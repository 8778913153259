<template>
    <div class="bg-white dark:bg-gray-900">
        <div class="mx-auto max-w-4xl divide-y divide-gray-900/10">
            <VForm
                v-slot="{ errors }"
                @submit="onSubmit">
                <div class="flex flex-col gap-6">
                    <BaseInput
                        v-model="model.email"
                        :label="$t('auth.email')"
                        rules="required|email|max:255"
                        required
                        type="email"
                        name="email"
                        autocomplete="username" />
                    <BaseInput
                        v-model="model.password"
                        :label="$t('auth.password')"
                        rules="required"
                        required
                        type="password"
                        name="password"
                        autocomplete="current-password" />

                    <div class="flex flex-col sm:flex-row gap-4 justify-between items-center">
                        <div>
                            <BaseCheckbox
                                v-model="model.remember"
                                name="stayLoggedIn">
                                {{ $t('auth.stayLoggedIn') }}
                            </BaseCheckbox>
                        </div>

                        <div class="text-sm">
                            <span class="font-semibold text-primary-600 hover:text-primary-500 cursor-pointer">
                                {{ $t('auth.forgotPassword') }}
                            </span>
                        </div>
                    </div>

                    <BaseButton
                        class="w-full"
                        :disabled="loading || !!Object.keys(errors).length"
                        native-type="submit">
                        <span v-if="!loading">{{ $t('auth.login') }}</span>
                        <span v-else>
                            <LoadingSpinner />
                            {{ $t('auth.pleaseWait') }}
                        </span>
                    </BaseButton>
                </div>
            </VForm>
        </div>
    </div>
</template>

<script setup>
import {useAuthStore} from '@/store/auth'
import {useSlideOverStore} from "@/store/slideOver";

const authStore = useAuthStore()
const notification = useNotification()
const slideOverStore = useSlideOverStore()

const model = ref({
    email: null,
    password: null,
    remember: true
})
const shake = ref(false)
const loading = ref(false)
const showPassword = ref(false)

async function onSubmit() {
    loading.value = true

    await $lara.post('/app/spa/login', {
        body: {...model.value}
    }).then(async response => {
        await authStore.successfulLogin()
        slideOverStore.resetSlideOver()
        loading.value = false
    }).catch(async error => {
        loading.value = false
        shake.value = true

        notification.error(error.response._data?.message)
    })
}

</script>

<style scoped>

</style>